var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('template-layout',[_c('template',{slot:"drawers"},[_c('payments-list-filters-drawer',{attrs:{"defaultFilters":_vm.defaultFilters,"filters":_vm.filters},on:{"update:filters":function($event){_vm.filters=$event}},model:{value:(_vm.showFiltersDrawer),callback:function ($$v) {_vm.showFiltersDrawer=$$v},expression:"showFiltersDrawer"}})],1),_c('template',{slot:"menu"},[_c('accounting-menu')],1),_c('template',{slot:"content"},[_c('v-container',[_c('v-toolbar',{staticClass:"mb-2",attrs:{"flat":"","dense":"","color":"transparent","height":"60"}},[_c('v-toolbar-title',[_c('span',{staticClass:"headline"},[_vm._v("Pagos")])]),_c('v-spacer'),_c('v-toolbar-title',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-4",attrs:{"icon":""},on:{"click":function($event){_vm.showFiltersDrawer = !_vm.showFiltersDrawer}}},on),[_c('v-icon',[_vm._v("mdi-tune")])],1)]}}])},[_c('span',[_vm._v("Filtrar")])])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(_vm.filters.createdAt)?_c('span',{staticClass:"font-weight-regular grey--text"},[_vm._v(" Mostrando del "+_vm._s(_vm._f("moment")(_vm.filters.createdAt.gte,'dddd D [de] MMMM YYYY'))+" al "+_vm._s(_vm._f("moment")(_vm.filters.createdAt.lte,'dddd D [de] MMMM YYYY'))+" ")]):_vm._e()]),_vm._l((_vm.stats),function(stat){return _c('v-col',{key:stat.name,attrs:{"cols":"12","sm":"4"}},[_c('v-card',[_c('v-card-text',[_c('h5',{staticClass:"subtitle-1 font-weight-regular grey--text"},[_vm._v(_vm._s(stat.method))]),_c('h1',{staticClass:"display-1 black--text"},[_vm._v(_vm._s(_vm._f("toCurrency")(stat.total)))])])],1)],1)})],2),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-0",attrs:{"hide-default-header":"","hide-default-footer":"","headers":_vm.headers,"loading":_vm.isLoading,"items":_vm.payments,"items-per-page":-1,"sort-by":"id","sort-desc":""},on:{"click:row":_vm.onPaymentSelected},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_vm._v(" Pago #"+_vm._s(item.id)+" ")]}},{key:"item.paymentMethod",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.paymentMethod.name)+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","x-small-chip":"","color":_vm.getPaymentStatusColor(item.status),"text-color":"white"}},[_vm._v(" "+_vm._s(_vm.getPaymentStatusName(item.status))+" ")])]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toCurrency")(item.total))+" ")]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" Creado por "),(item.createdBy)?_c('span',[_vm._v(_vm._s(item.createdBy.name))]):_vm._e(),_vm._v(" "+_vm._s(_vm._f("moment")(item.createdAt,'DD/MM/YY h:mm:ss a'))+" ")]}},{key:"body.append",fn:function(){return [_c('tr',{on:{"click":_vm.onLoadMoreSelected}},[_c('td',{attrs:{"colspan":"6"}},[(_vm.isLoading)?_c('v-row',{attrs:{"justify":"center"}},[_c('hollow-dots-spinner',{attrs:{"animation-duration":1500,"size":64,"color":"#cddc39"}})],1):_c('v-row',{attrs:{"justify":"center"}},[_c('v-btn',{attrs:{"text":"","small":"","color":"primary"}},[_vm._v("Mostrar más")])],1)],1)])]},proxy:true}])})],1)],1)],1)],1)],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }