<template>
    <template-layout>
        <template slot="drawers">
            <payments-list-filters-drawer
                v-model="showFiltersDrawer"
                :defaultFilters="defaultFilters"
                :filters.sync="filters"
            />
        </template>

        <template slot="menu">
            <accounting-menu />
        </template>

        <template slot="content">
            <v-container>
                <v-toolbar flat dense color="transparent" height="60" class="mb-2">
                    <v-toolbar-title>
                        <span class="headline">Pagos</span>
                    </v-toolbar-title>

                    <v-spacer></v-spacer>

                    <v-toolbar-title>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn class="mr-4" icon @click="showFiltersDrawer = !showFiltersDrawer" v-on="on"><v-icon>mdi-tune</v-icon></v-btn>
                            </template>
                            <span>Filtrar</span>
                        </v-tooltip>
                    </v-toolbar-title>
                </v-toolbar>

                <v-row>
                    <v-col cols="12">
                        <span v-if="filters.createdAt" class="font-weight-regular grey--text">
                            Mostrando del {{ filters.createdAt.gte | moment('dddd D [de] MMMM YYYY') }} al {{ filters.createdAt.lte | moment('dddd D [de] MMMM YYYY') }}
                        </span>
                    </v-col>

                    <v-col cols="12" sm="4" v-for="stat in stats" v-bind:key="stat.name">
                        <v-card>
                            <v-card-text>
                                <h5 class="subtitle-1 font-weight-regular grey--text">{{ stat.method }}</h5>
                                <h1 class="display-1 black--text">{{ stat.total | toCurrency }}</h1>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12">
                        <v-card>
                            <v-card-text>
                                <v-data-table
                                    hide-default-header
                                    hide-default-footer
                                    class="elevation-0"
                                    :headers="headers"
                                    :loading="isLoading"
                                    :items="payments"
                                    :items-per-page="-1"
                                    sort-by="id"
                                    sort-desc
                                    @click:row="onPaymentSelected"
                                >
                                    <template v-slot:item.id="{ item }">
                                        Pago #{{ item.id }}
                                    </template>

                                    <template v-slot:item.paymentMethod="{ item }">
                                        {{ item.paymentMethod.name }}
                                    </template>

                                    <template v-slot:item.status="{ item }">
                                        <v-chip
                                            label
                                            x-small-chip
                                            :color="getPaymentStatusColor(item.status)"
                                            text-color="white"
                                        >
                                            {{ getPaymentStatusName(item.status) }}
                                        </v-chip>
                                    </template>

                                    <template v-slot:item.total="{ item }">
                                        {{ item.total | toCurrency }}
                                    </template>

                                    <template v-slot:item.createdAt="{ item }">
                                        Creado por <span v-if="item.createdBy">{{ item.createdBy.name }}</span> {{ item.createdAt | moment('DD/MM/YY h:mm:ss a') }}
                                    </template>

                                    <template v-slot:body.append>
                                        <tr @click="onLoadMoreSelected">
                                            <td colspan="6">
                                                <v-row v-if="isLoading" justify="center">
                                                    <hollow-dots-spinner :animation-duration="1500" :size="64" color="#cddc39" />
                                                </v-row>

                                                <v-row v-else justify="center">
                                                    <v-btn text small color="primary">Mostrar más</v-btn>
                                                </v-row>
                                            </td>
                                        </tr>
                                    </template>
                                </v-data-table>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </template>
    </template-layout>
</template>

<script>
    import TemplateLayout from '@/layout/TemplateLayoutWithMenu.vue';
    import PaymentsListFiltersDrawer from './PaymentsListFiltersDrawer';
    import { HollowDotsSpinner } from 'epic-spinners';
import AccountingMenu from '../Accounting/AccountingMenu.vue';

    export default {
        components: {
    TemplateLayout,
    PaymentsListFiltersDrawer,
    HollowDotsSpinner,
    AccountingMenu
},

        data() {
            return {
                showPaymentViewDrawer: false,
                showFiltersDrawer: false,

                filters: {},
                defaultFilters: {
                    createdAt: {
                        lte: this.$moment().toISOString(),
                        gte: this.$moment().subtract(7, 'd').set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toISOString()
                    }
                },

                selectedMovement: null,

                headers: [
                    { text: 'id', value: 'id', sortable: false },
                    { text: 'Método', value: 'paymentMethod', sortable: false },
                    { text: 'Importe', value: 'total', sortable: false },
                    { text: 'Estado', value: 'status', sortable: false },
                    { text: 'Autorizacion', value: 'authorizationCode', sortable: false },
                    { text: 'Fecha', value: 'createdAt', sortable: false }
                ],

                offset: 0
            };
        },

        computed: {
            isLoading() {
                return this.$store.getters['payments/isLoading']();
            },

            payments() {
                return this.$store.getters['payments/getAll']();
            },

            stats() {
                return this.$store.getters['payments/getStats']();
            }
        },

        watch: {
            filters: {
                handler(val) {
                    // this.updateRouteQuery();
                    this.fetchPayments();
                },

                deep: true
            }
        },

        async mounted() {
            this.filters = { ...this.defaultFilters };
        },

        methods: {
            async fetchPayments() {
                this.$store.dispatch('payments/clear');

                this.$store.dispatch('payments/fetchStats', {
                    ...this.filters
                });

                await this.$store.dispatch('payments/fetchAll', {
                    offset: 0,
                    limit: 20,
                    ...this.filters
                });
            },

            getPaymentStatusName(state) {
                switch (state) {
                case 'approved':
                    return 'Aprobado';
                case 'rejected':
                    return 'Rechazado';
                case 'processing':
                    return 'Pendiente';
                }
            },

            getPaymentStatusColor(state) {
                switch (state) {
                case 'approved':
                    return 'green';
                case 'rejected':
                    return 'red';
                case 'processing':
                    return 'yellow';
                }
            },

            onPaymentSelected(payment) {
                this.showPaymentViewDrawer = true;
                this.selectedPayment = payment;
            },

            onBackSelected() {
                // if(window.history.length > 2) {
                //     this.$router.go(-1);
                // }
                // else {
                //     this.$router.push({ name: 'cashAccountList' });
                // }
            },

            async onLoadMoreSelected() {
                if(this.isLoading) {
                    return;
                }

                this.offset += 20;
                await this.$store.dispatch('payments/fetchAll', {
                    offset: this.offset,
                    limit: 20,
                    ...this.filters
                });
            }
        }
    };
</script>

<style lang="scss" scoped>
</style>
